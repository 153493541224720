<template>
  <v-container class="is-md py-12">
    <!-- header -->
    <v-row align="center" class="mb-3">
      <v-col cols="6">
        <v-btn
          plain small :ripple="false" text
          to="/cases/search"
          class="unwidth px-0"
        >
          <v-icon class="mr-1">
            mdi-keyboard-backspace
          </v-icon>
          Back to boxes
        </v-btn>
      </v-col>

      <v-col cols="6" class="text-right">
        <v-dialog
          v-if="box"
          v-model="isDeleting"
          max-width="400"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              small text color="error"
              class="mr-3 "

              v-bind="attrs"
              v-on="on"
            >
              <v-icon small left class="mr-1">
                mdi-archive-remove
              </v-icon>
              <span class="font-weight-bold">{{ box.archived ? 'Unarchive' : 'Archive' }}</span>
            </v-btn>
          </template>
          <v-card color="grey600">
            <v-card-title class="text-h5">
              Are you sure?
            </v-card-title>
            <v-card-text>
              <p class="text-body-2 mb-0">
                Archived boxes can no longer be opened and will not show up in searches (also admin panel) anymore.
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="isDeleting = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                text
                @click="onSetArchive(!box.archived)"
              >
                {{ box.archived ? 'Unarchive' : 'Archive' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-chip label color="primary--gradient" light>
          <span class="font-weight-semibold text-uppercase">Editing box</span>
        </v-chip>
      </v-col>
    </v-row>

    <!-- loaders -->
    <v-row v-if="loading.box || !formDataReady">
      <v-col cols="2">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader class="mb-7" height="180" type="image" />
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader type="image" />
      </v-col>
    </v-row>

    <v-row v-else-if="error">
      <v-col cols="12" md="6" offset-md="3">
        <v-alert type="error" color="red darken-4">
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>

    <!-- box -->
    <BoxForm
      v-else
      :value="forms"
      :markets="markets"
      :categories="categories"
      :loading="loading.submit"
      @submit="onSubmit"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import BoxForm from './components/BoxForm'

export default {
  metaInfo() {
    const { box, boxId } = this
    return {
      title: `Editing box: ${box?.name} - ${boxId}`,
    }
  },
  components: {
    BoxForm,
  },
  data() {
    return {
      loading: {
        box: false,
        submit: false,
        data: false,
      },
      isDeleting: false,
      categories: [],
      markets: [],
      box: null,
      error: null,
      forms: null,
    }
  },
  computed: {
    ...mapGetters({
      session: 'auth/session',
    }),
    boxId() {
      return this.box?._id ?? this.$route.params.id
    },
    formDataReady() {
      return !!this.markets.length && !!this.categories.length
    },
  },
  async created() {
    await this.fetchApiData(this.boxId)
    await this.getFormData()
  },
  methods: {
    async getFormData() {
      try {
        this.loading.data = true
        const [markets, categories] = await Promise.all([
          this.$socket.request('admin.markets.get'),
          this.$socket.request('admin.cases.categories'),
        ])

        this.markets = markets
        this.categories = categories
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.data = false
      }
    },
    async fetchApiData(boxId = this.boxId) {
      try {
        this.error = null
        this.loading.box = true
        const box = await this.$socket.request('admin.cases.getById', boxId)

        this.box = box
        this.forms = {
          _id: box._id,
          name: box.name,
          slug: box.slug,
          description: box.description,
          type: box.type,
          disabled: box.disabled,
          archived: box.archived,
          tags: box.tags,
          categories: box.categories.map(i => i._id),
          market: box.market?._id,
          content: box.content,
        }
      } catch (error) {
        this.error = error.message
      } finally {
        this.loading.box = false
      }
    },
    async onSubmit(forms) {
      if (this.loading.submit) return
      this.loading.submit = true

      try {
        await this.$socket.request('admin.cases.set', {
          ...forms,
          content: {
            items: forms.content.items.map(i => i._id),
            edge: forms.content.edge,
            odds: forms.content.odds,
          },
        })

        this.$toast.success('Box updated successfully')
        this.$router.push('/cases/search')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
    async onSetArchive(value) {
      if (this.loading.submit) return
      this.loading.submit = true

      this.isDeleting = false

      try {
        await this.$socket.request('admin.cases.setArchived', {
          _id: this.boxId,
          archived: value,
        })

        this.box.archived = value

        this.$toast.success(`Box ${value ? 'archived' : 'unarchived'} successfully`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
